import { CSSProperties } from "react";

import { Theme, darken } from "@mui/material";
import { makeStyles } from "tss-react/mui";

export const useRegistrationStyles = makeStyles<void, "actionsPullRight">()(
  (theme: Theme, _params, classes) => {
    const leftBox = {
      display: "flex",
      alignItems: "start",
      flexDirection: "column",
      justifyContent: "center",
      marginTop: "-30px",
      [theme.breakpoints.down("md")]: {
        alignItems: "start",
        maxWidth: "unset",
      },
    } as CSSProperties;

    const actionButton = {
      minWidth: "168px",
      justifyContent: "center",
      fontWeight: 800,
      fontSize: "1.065rem",
      [theme.breakpoints.down("md")]: {
        fontSize: "0.93rem",
      },
    } as CSSProperties;

    return {
      container: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: "1rem",
        height: "calc(100vh - 235px)",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
          gap: "0",
          padding: "0px",
          marginTop: "32px",
        },
      },
      loginContainer: {
        [theme.breakpoints.down("md")]: {
          marginTop: "62px",
        },
      },
      resetPasswordContainer: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: "34px",
        [theme.breakpoints.down("md")]: {
          gridTemplateColumns: "1fr",
          gap: "0",
          marginTop: "24px",
          alignItems: "flex-start",
          display: "block",
        },
      },
      cardContainer: {
        boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
        padding: "40px 20px",
        marginTop: 55,
        borderRadius: 16,
        [theme.breakpoints.down("md")]: {
          padding: "2px",
          boxShadow: "unset",
          backgroundColor: "unset",
          marginTop: "10px",
        },
      },
      registrationContainer: {},
      smRegistrationContainer: {
        marginTop: "20vh",
        [theme.breakpoints.down("md")]: {
          marginTop: "40px",
        },
      },
      actions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(2),
          display: "block",
          textAlign: "center",
          "& button, & a": {
            width: "100%",
            justifyContent: "center",
            marginTop: "1em",
          },
        },
        [`&.${classes.actionsPullRight}`]: {
          justifyContent: "flex-end",
        },
      },
      actionsPullRight: {},
      actionsMargin: {
        marginTop: "20px",
      },
      backIconButton: {
        padding: "8px",
        marginTop: 24,
        marginLeft: -12,
        gap: 12,
        fontWeight: "800",
        fontSize: "1.0625rem",
        lineHeight: 1.29,
        color: theme.mode.text.heading,
        "& .icon": {
          background: "white",
          borderRadius: "50% 50%",
          display: "flex",
          alignContent: "center",
          justifyItems: "center",
          padding: 9,
          boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.08)",
        },

        "& svg": {
          height: 18,
          width: 18,
        },
      },
      spinnerWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3em",
      },
      leftBoxLogin: {
        ...leftBox,
        maxWidth: "475px",
        alignItems: "left",
      },
      leftBoxSignup: {
        ...leftBox,
        maxWidth: "368px",
        marginTop: 0,
      },
      leftBoxResetPassword: {
        ...leftBox,
        maxWidth: "489px",
        [theme.breakpoints.down("md")]: {
          marginTop: "0px",
        },
      },
      leftBoxNewPassword: {
        ...leftBox,
        marginTop: "-10px",
      },
      title: {
        fontSize: "1.6875rem",
        color: "#3f3d56",
        fontWeight: 800,
        lineHeight: "1.22em",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal",
        marginBottom: "10px",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.3125rem",
          lineHeight: "normal",
        },
      },
      subtitle: {
        fontSize: "1.0625rem",
        lineHeight: "1.75rem",
        fontWeight: "bold",
        padding: 0,
        margin: "10px 0px 20px 0px",
        color: "#3f3d56",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.0625rem",
          lineHeight: "1.4375rem",
        },
      },
      description: {
        fontSize: "0.9375rem",
        lineHeight: "22px",
        color: "#6c6b78",
        "p+p": {
          paddingTop: "3px",
        },
        [theme.breakpoints.down("md")]: {
          fontSize: "15px",
          marginBottom: "1em",
        },
      },
      leftAction: {
        fontSize: "1.313rem",
        fontWeight: 600,
        color: "#6c6b78",
        [theme.breakpoints.down("md")]: {
          fontSize: "1.065rem",
        },
      },
      error: {
        color: "red",
        fontWeight: "bold",
      },
      forgotPassword: {
        display: "block",
        textAlign: "right",
        marginBottom: theme.spacing(4),
        marginTop: "-9px",
        color: theme.palette.primary.main,
        fontWeight: "800",
        padding: "0px 8px",
        fontSize: "0.93rem",
      },
      hintContainer: {
        padding: "0 10px",
        fontSize: "0.9375rem",
        color: theme.mode.text.description,
        fontWeight: 600,
      },
      passwordHint: {
        margin: "-1em 0 5px 0",
        fontSize: "0.9375rem",
        fontWeight: "normal",
        lineHeight: "1.47",
        color: theme.mode.text.description,
      },
      hint: {
        marginBottom: "5px",
      },
      moreHint: {
        cursor: "default",
      },
      moreHintItemContainer: {
        display: "inline-flex",
        alignItems: "center",
        cursor: "pointer",
      },
      moreTooltip: {
        backgroundColor: "#fff",
        boxShadow: "0 4px 24px 0 rgba(63,61,86,0.1)",
        color: "#6c6b78",
      },
      link: {
        color: theme.palette.primary.main,
      },
      actionButton: {
        ...actionButton,
      },
      actionButtonConfirm: {
        ...actionButton,
        textTransform: "uppercase",
      },
      cardMargins: {
        padding: "40px 20px",
      },
      captchaContainer: {
        marginTop: 20,
      },
      headerActionContainer: {
        fontSize: "1.065rem",
        fontWeight: 600,
      },
      headerAction: {
        fontWeight: 800,
        cursor: "pointer",
      },
      checkbox: {
        padding: 0,
        "& > *": {
          height: "auto",
        },
      },
      passwordField: {
        marginBottom: "24px",
      },
      rememberMeLabel: {
        fontSize: "0.8125rem",
        marginLeft: theme.spacing(1),
        fontWeight: 600,
        color: "#6c6b78",
      },
      loginError: {
        marginTop: theme.spacing(-6.3),
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(1),
          textAlign: "center",
        },
      },
      newPasswordError: {
        marginBottom: "24px",
        [theme.breakpoints.down("md")]: {
          marginTop: theme.spacing(1),
          textAlign: "center",
        },
      },
      spacer: {
        height: theme.spacing(5),
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      confirmCenterContainer: {
        marginTop: "110px",
        fontSize: "1.6rem",
        lineHeight: "2.25rem",
        wordWrap: "break-word",
        [theme.breakpoints.down("md")]: {
          marginTop: "70px",
        },

        "& .iconWrapper": {
          margin: "0 auto",
          display: "grid",
          placeItems: "center",
          borderRadius: 40,
          color: "#fff",
          backgroundColor: theme.palette.primary.main,
          width: "80px",
          height: "80px",
          marginBottom: "28px",
          "& svg": {
            width: 38,
            height: 38,
          },
        },

        "& .confirmCenter": {
          width: 460,
          margin: "0 auto",
          [theme.breakpoints.down("sm")]: {
            width: "unset",
          },
        },

        "& .resendEmailButton": {
          fontWeight: "800",
          cursor: "pointer",
          color: theme.palette.primary.main,
          display: "inline-block",
        },
      },
      confirmTitle: {
        fontSize: "1.6875rem",
        fontWeight: 800,
        lineHeight: "1.2",
        marginBottom: "1rem",
        color: theme.mode.text.heading,
      },
      confirmText: {
        marginBottom: "2rem",
        fontSize: "0.9375rem",
        color: theme.mode.text.description,
        lineHeight: "1.47",
      },
      confirmActionContainer: {
        display: "flex",
        justifyContent: "center",
      },
      navigateHomeContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: theme.spacing(2),
        "& p": {
          fontSize: "1rem",
          fontWeight: "600",
          "&:hover": {
            textDecoration: "underline",
            cursor: "pointer",
          },
        },
      },
      signupHeaderAction: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 16,

        "& span": {
          fontSize: "0.9375rem",
          fontWeight: "bold",
          lineHeight: 1.6,
          textAlign: "right",
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        },

        "& a": {
          backgroundColor: "white",
          color: theme.palette.primary.main,
          width: "unset",
          minHeight: "unset",
          minWidth: "unset",
          padding: "9px 35px",
          transition: "color 200ms",
          fontWeight: "800",
          lineHeight: 1.47,
          fontSize: "0.9375rem",
          "&:hover": {
            backgroundColor: "white",
            color: darken(theme.palette.primary.main, 0.2),
          },
        },
      },
      smRegistrationBackButton: {
        top: 17,
        left: "initial",
        position: "absolute",
        fontSize: "1.0625rem !important",
        [theme.breakpoints.down("md")]: {
          top: "-32px",
          justifyContent: "left",
          position: "relative",
        },
      },
      smContainer: {
        "& .sm_title, & .sm_separator": {
          textAlign: "center",
          fontSize: "0.9375rem",
        },
        "& .sm_buttons_container": {
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          userSelect: "none",
          "& .sm_button": {
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            gap: 8,
            padding: 16,
            width: 122,
            borderRadius: 12,
            boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.12)",
            cursor: "pointer",
            transition: "box-shadow 0.2s ease",
            "&:hover": {
              boxShadow: "0 0 16px 0 rgba(63, 61, 86, 0.17)",
            },

            [theme.breakpoints.down("md")]: {
              width: 65,
            },

            "& .sm_icon": {
              minWidth: 16,
              marginTop: 3,
            },
            "& .sm_label": {
              color: theme.mode.text.heading,
              fontWeight: "bold",
              fontSize: "0.8125rem",
              [theme.breakpoints.down("md")]: {
                display: "none",
              },
            },
          },
        },
        "& .sm_separator": {
          margin: "24px 0px",

          "& hr": {
            backgroundColor: "#e4e4e4",
            height: 2,
            border: "0",
          },
          "& span": {
            display: "block",
            width: 50,
            position: "relative",
            marginTop: "-22px",
            backgroundColor: "#fff",
            marginLeft: "auto",
            marginRight: "auto",
            [theme.breakpoints.down("md")]: {
              backgroundColor: theme.mode.background.default,
            },
          },
        },
      },
    };
  },
);
