import React, { ReactNode } from "react";

import { Box, Container, Theme } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import Link from "@/common/components/Link";
import Logo from "@/common/components/Logo";

type TProps = {
  children: JSX.Element;
  logo?: boolean;
  hideTopBar?: boolean;
  headerAction?: ReactNode;
  onLogoClick?: () => void;
};

export const GeneralLayoutLocalStorageKey = "GeneralLayoutLocalStorageKey";

const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    height: 80,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      height: 60,
    },
  },
  headerContainer: {
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
    paddingLeft: 0,
    [theme.breakpoints.down("md")]: {
      paddingRight: "16px",
    },
  },
  logo: {
    height: "3em",
    fontSize: "0.875rem",
  },
  logoContainer: {
    margin: theme.spacing(1),
  },
  contentWrapper: {
    height: "calc(100vh - 80px)",
    minHeight: "calc(100vh - 267px)",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 999,
    [theme.breakpoints.down("md")]: {
      height: "calc(100% - 60px)",
      minHeight: "calc(var(--vh, 1vh) * 100 - 60px)",
    },
  },
  content: {
    flexGrow: 1,
  },
}));

function AuthPage({ children, headerAction, onLogoClick, hideTopBar }: TProps) {
  const { classes } = useStyles();

  const handleLogoClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onLogoClick) {
      e.preventDefault();
      onLogoClick();
    }
  };

  const headerButton = () => (
    <Box>
      <Link href="/">
        <a onClick={handleLogoClick} className={classes.logoContainer}>
          <Logo className={classes.logo} fill="#FFFFFF"></Logo>
        </a>
      </Link>
    </Box>
  );

  return (
    <div>
      {!hideTopBar && (
        <div className={classes.header}>
          <Container className={classes.headerContainer} maxWidth={"lg"}>
            {headerButton()}
            {headerAction}
          </Container>
        </div>
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.content}>{children}</div>
      </div>
    </div>
  );
}

export default AuthPage;
